<template>
  <div class="handleDetails">
    <overflowYHidden>
      <baseInfo :report="report"></baseInfo>
      <handleInfo ref="handleInfo" :report="report"></handleInfo>
      <div class="footer">
        <el-button class="cancel" round @click="reportHandle(0)">
          维修不通过</el-button
        >
        <el-button class="remindReport" round @click="reportHandle(1)"
          >维修通过</el-button
        >
      </div>
    </overflowYHidden>
  </div>
</template>

<script>
import baseInfo from "./baseInfo.vue";
import handleInfo from "./handleInfo.vue";
import overflowYHidden from "@/components/overflowYHidden";
export default {
  components: { baseInfo, handleInfo, overflowYHidden },
  data() {
    return {
      remark: "",
      id: "",
      report: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.$nextTick(() => {
      this.reportDataInfo();
    });
  },
  methods: {
    reportDataInfo() {
      this.$showLoading({
        target: ".handleDetails",
      });
      this.$api.deviceRepair
        .reportDataInfo({
          id: this.id,
          photo: "1,2,3,4,5",
        })
        .then((res) => {
          this.report = res.data.report;
          this.$hideLoading({
            target: ".handleDetails",
          });
        })
        .catch((res) => {
          this.$hideLoading({
            target: ".handleDetails",
          });
        });
    },
    reportHandle(pass) {
      this.remark = this.$refs.handleInfo.remark;
      this.$dialog.confirm({
        message: `确认维修${pass == 0 ? "未" : ""}通过？`,
        beforeClose: (action, done) => {
          if (action === "confirm") {
            this.$api.deviceRepair
              .reportHandle({
                id: this.id,
                pass: pass,
                remark: this.remark,
              })
              .then((res) => {
                done();
                this.$toast.success("操作成功");
                this.$back();
              })
              .catch(() => {
                this.$toast.fail("操作失败");
                done();
              });
          } else {
            done();
          }
        },
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.handleDetails {
  height: 100%;
  overflow: hidden;
  padding: 10px;
  .footer {
    margin: 20px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    .remindReport {
      width: 150px;
      border-radius: 20px;
      background: #3e73fb;
      color: #ffffff;
    }
    .cancel {
      border-radius: 20px;
      width: 150px;
      background: #ff2e2e;
      color: #ffffff;
    }
  }
  .mian {
    background: $background_color2;
    padding: 12px;
    border-radius: 2px;
    margin-bottom: 10px;
    .item {
      font-size: 14px;
      height: 38px;
      line-height: 37px;
      .itemLabel {
        color: $color3;
      }
      .itemValue {
        float: right;
        .inputView {
          width: 132px;
          float: left;
          font-size: 14px;
        }
        .scanning {
          color: #3e73fb;
          img {
            width: 16px;
            vertical-align: sub;
          }
        }
      }
    }
    .title {
      font-size: 14px;
      color: $color3;
      padding-bottom: 6px;
      color: $color1;
      font-weight: 500;
    }
  }
}
</style>